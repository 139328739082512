import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import analysisCard from "../ToolsCard.vue";
import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import Pagination from "@/components/pagination";
import GenericModal from "@/components/genericModal";
import axios from "../../../../axios/osint.js";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {DateTime} from "luxon";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import {getBackgroundStatus, monitoringSearchData, getResultsID} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import neoSwitch from "@/components/toggle-switch";
import SaasAxios from "@/axios";

export default {
    name: "neo-github",
    mixins: [toolsHelper],
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "analysis-card": analysisCard,
        "neo-generic-modal": GenericModal,
        NeoAnalyse,
        "download-csv": JsonCSV,
        neoSwitch,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            emailList: [],
            loaderController: {
                github: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                search: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            allGithub: [],
            csvData: {
                githubSearches: [],
                githubUsername: [],
                github: [],
            },
            githubSearches: [],
            allGithubUsernameData: [],
            alertMessage: {
                success: null,
                error: null,
            },
            case_id: "",
            headers: [
                {
                    text: "",
                    value: "details",
                },
            ],
            itemKey: "username",
            searchCaseFilter: "",
            selectable: false,
            hasAction: false,
            searchKeyword: "",
            searchEmail: "",
            email: "",
            showFile: false,
            fileContent: "",
            isHeader: false,
            isFooter: true,
            codeContent: {},
            searchType: "",
            selectedItem: null,
            usernameList: [],
            inputValue: "Email",
            inputOptions: [
                {
                    group: "Field Type",
                    categories: ["Email", "Username", "Code Search"],
                },
            ],
            selectedItemUsername: null,
            searchUsername: "",
            showResult: false,
            triggerRoute: true,
            selectNameVal: "",
            selectVal: "",
            searchClicked: false,
            isMonitoringTab: false,
            tabName: "",
            request_id: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getCaseEmails", "getCaseUsernames", "getMonitoringDateFilter", "getSelectedToolQuery", "getAllMonitoringData", "getProduct"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        csvFields() {
            return {
                githubSearches: ["name", "repository", "text_matches", "html_url", "path"],
                githubUsername: ["email", "count"],
                github: ["id", "name", "username", "email", "location", "bio", "avatar_url", "url", "blog", "twitter_username", "public_repos", "public_gists", "followers", "following", "created_at", "updated_at"],
            };
        },

        query() {
            return this.selectedItem?.value || this.selectedItemUsername?.value || this.searchKeyword;
        },
        checkMarkAllDone() {
            let res = false;
            switch (this.searchType) {
                case "email":
                    res = this.allGithub.every((allGit) => allGit.changes_viewed);
                    break;
                default:
                    res = this.allGithubUsernameData.every((allGit) => allGit.changes_viewed);
                    break;
            }
            return res;
        },
    },
    watch: {
        // allGithub() {
        //     if (this.allGithub.length > 0) {
        //         this.$emit("searchResult", { data: this.allGithub, source: "github", searchType: "email" });
        //     }
        // },
        "$route.query"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },

        // searchKeyword() {
        //     this.showResult = false;
        // },

        // selectedItemUsername: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },

        // selectedItem: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.isMonitoringTab = this.$route.name === "monitoring";
        this.tabName = this.$route.name;
        const dataRes = this.getToolsData(this.tabName, "github-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        // this.SET_ALL_TOOLS_DATA({ ...this._data, "tool-name": "github-tool" });
        this.setToolsData(this.tabName, "github-tool");
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAnalysisDropdownData", "getAllCaseEmails", "getAllCaseUsernames"]),
        getBackgroundStatus,
        getResultsID,

        async getToolData() {
            this.emailList = await this.getDropdownData("email");
            this.usernameList = await this.getDropdownData("username");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: list.split("List")[0],
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "github", [...this.emailList, ...this.usernameList], data);
            allChecked = [...this.emailList, ...this.usernameList]?.length ? ([...this.emailList, ...this.usernameList].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        switchInputType() {
            this.showResult = false;
            this.selectedItem = null;
            this.selectedItemUsername = null;
            this.searchKeyword = "";
            this.searchEmail = "";
            this.searchUsername = "";
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        setInput() {
            if (this.inputValue === "Email") {
                this.searchType = "email";
                this.searchEmail = this.selectedItem.value.trim();
            } else {
                this.searchType = "username";
                this.searchUsername = this.selectedItemUsername.value;
            }
            // this.search();
        },

        setTheCustomInput(value) {
            if (this.inputValue === "Email") {
                this.searchType = "email";
                this.searchEmail = value.trim();
                this.selectedItem = {
                    value: value,
                };
            } else {
                this.searchType = "username";
                this.searchUsername = value;
                this.selectedItemUsername = {
                    value: value,
                };
            }
            // this.search();
        },

        setSearchType(searchType) {
            this.showResult = false;
            this.searchType = searchType;
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("EEE, dd MMM yyyy, hh:mm:ss a");
        },
        getResultFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },
        getExtensionName(extension) {
            let type = "";
            switch (extension) {
                case "json":
                    type = "JSON";
                    break;
                case "xml":
                    type = "XML";
                    break;
                case "js":
                    type = "JavaScript";
                    break;
                case "html":
                    type = "HTML";
                    break;
                case "css":
                    type = "CSS";
                    break;
                case "vue":
                    type = "VueJS";
                    break;
                case "ts":
                    type = "TypeScript";
                    break;
            }
            return type;
        },
        getExtensionIconColor(extension) {
            let color = "";
            switch (extension) {
                case "json":
                    color = "red";
                    break;
                case "xml":
                    color = "purple";
                    break;
                case "js":
                    color = "green";
                    break;
                case "html":
                    color = "orange";
                    break;
                case "css":
                    color = "blue";
                    break;
                case "vue":
                    color = "yellow";
                    break;
                case "ts":
                    color = "grey";
                    break;
            }
            color = color ? "background-color:" + color : "";
            return color;
        },
        manualSearch() {
            this.triggerRoute = false;
            this.search();
        },

        /**
         * Fired when search queries are clicked && monitoring tab is active
         * @params type {string}
         */
        async monitoringSearch(type) {
            this.csvData = {
                githubSearches: [],
                githubUsername: [],
                github: [],
            };
            this.allGithub = [];
            this.allGithubUsernameData = [];
            this.githubSearches = [];
            this.startLoader("github");

            let values = await monitoringSearchData(this.case_id, null, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            this.showResult = true;
            // this.redirectToQuery()
            // this.allData = values;
            // this.isLoading = false;
            switch (type) {
                case "email":
                    this.allGithub = values.flatMap((val) => {
                        let json = val.profile;
                        json.url = val.url;
                        json.username = val.username;
                        json.changes_viewed = val.changes_viewed;
                        json.doc_source = val.doc_source;
                        json.monitoring_status = val.monitoring_status;
                        json.monitoring_id = val.monitoring_id;
                        json.monitoring_result_status = val.monitoring_result_status;
                        json.monitoringDate = val.monitoringDate;
                        return json;
                    });
                    this.csvData.github = this.convertToCSV(this.allGithub);
                    break;
                case "username":
                    this.allGithubUsernameData = values;
                    this.csvData.githubUsername = this.convertToCSV(this.allGithubUsernameData);
                    break;
                default:
                    break;
            }
            this.resetLoader("github");
            // this.searchKeyword = this.inputValue.value.toLowerCase()
        },

        async search() {
            this.showResult = false;
            this.searchClicked = true;
            const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            if (this.searchType === "email" && emailRegex.test(this.searchEmail.trim())) {
                if (this.isMonitoringTab) {
                    await this.monitoringSearch("email");
                    return;
                }
                await this.getGithub();
                await this.setSearchedGithubUser(this.searchEmail.trim(), "email");
                this.triggerRoute = true;
            } else if (this.searchType === "text" && this.searchKeyword) {
                await this.searchGithub("text");
                this.triggerRoute = true;
            } else if (this.searchType == "username" && this.searchUsername) {
                if (this.isMonitoringTab) {
                    await this.monitoringSearch("username");
                    return;
                }
                await this.getGithubUsernameData("username");
                await this.setSearchedGithubUser(this.searchUsername, "username");
                this.triggerRoute = true;
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },
        async setSearchedGithubUser(searchKeyword, valType) {
            const data = {
                value: searchKeyword,
                platform: "github",
                val_type: this.selectedItem?.val_type || valType,
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`${this.selectedItem?.val_type || valType}List`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`${this.selectedItem?.val_type || valType}List`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        getMatchCount(item) {
            let count = 0;
            if (item.text_matches) {
                for (let i = 0; i < item.text_matches.length; i++) {
                    count += item.text_matches[i].matches.length;
                }
            }
            return count;
        },

        getFragment(matches, index) {
            let text = "";
            let code = "";

            for (let i = 0; i < matches.length; i++) {
                text = text + `${matches[i].fragment}`;
                code = code + `${matches[i].fragment}`;
            }
            this.codeContent[index] = text;

            var element;
            this.$nextTick(() => {
                element = document.getElementById(`code-snippet-${index}`);

                for (let i = 0; i < matches.length; i++) {
                    for (let j = 0; j < matches[i].matches.length; j++) {
                        let regex1 = new RegExp(this.searchKeyword, "g");
                        let regex2 = new RegExp(this.searchKeyword.toLowerCase(), "g");
                        let regex3 = new RegExp(this.searchKeyword.toUpperCase(), "g");
                        let regex4 = new RegExp(this.searchKeyword.charAt(0).toUpperCase() + this.searchKeyword.substr(1).toLowerCase(), "g");
                        element.innerHTML = element.innerHTML.replace(regex1, function (match) {
                            return '<span class="highlight">' + match + "</span>";
                        });
                        element.innerHTML = element.innerHTML.replace(regex2, function (match) {
                            return '<span class="highlight">' + match + "</span>";
                        });
                        element.innerHTML = element.innerHTML.replace(regex3, function (match) {
                            return '<span class="highlight">' + match + "</span>";
                        });
                        element.innerHTML = element.innerHTML.replace(regex4, function (match) {
                            return '<span class="highlight">' + match + "</span>";
                        });
                    }
                }
            });
        },

        viewFile(url) {
            let content = "";
            axios
                .get(url)
                .then((response) => {
                    if (response && response.data && response.data.content) {
                        content = atob(response.data.content);
                        this.showFile = true;
                        this.fileContent = content;
                    }
                })
                .catch((error) => {});
        },

        async getGithub(isNext) {
            this.startLoader("github");
            this.allGithub = [];
            this.githubSearches = [];
            this.allGithubUsernameData = [];
            let data = {};
            this.csvData = {
                githubSearches: [],
                githubUsername: [],
                github: [],
            };
            let url = "/tools/github/email";
            data = {
                email: this.searchEmail.trim(),
            };
            this.$emit("searched", {
                data: {email: this.searchEmail.trim()},
                source: "github",
            });

            await axios
                .post(url, data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                })
                .then((response) => {
                    this.allGithub = [];
                    if (response?.data?.data?.profile) {
                        this.allGithub.push(response.data.data.profile);
                        this.allGithub[this.allGithub.length - 1].username = response.data.data.username;
                        this.allGithub[this.allGithub.length - 1].profile_url = response.data.data.url;
                        this.csvData.github = this.convertToCSV(this.allGithub);
                        this.showResult = true;

                        this.SET_ALL_TOOLS_DATA({
                            ...this._data,
                            "tool-name": "github-tool",
                        });
                    } else if (response && response.data && response.data.status == false) {
                        this.$toast.error(response.data.message);
                    } else if (response) {
                        this.$toast.error("No data found");
                    }
                    this.request_id = response?.data?.query_id;
                    this.redirectTo();
                    this.$emit("searchResult", {
                        data: this.allGithub,
                        source: "github",
                        searchType: "email",
                    });
                    this.resetLoader("github");
                })
                .catch((error) => {
                    this.resetLoader("github");
                    let message = "Please enter a valid input";
                    if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                    this.$toast.error(message);
                });
        },

        async searchGithub(isNext) {
            this.startLoader("search");
            this.githubSearches = [];
            this.allGithub = [];
            this.csvData = {
                githubSearches: [],
                githubUsername: [],
                github: [],
            };
            this.allGithubUsernameData = [];
            this.codeContent = {};
            let data = {};
            let url = "/tools/github/search";
            data = {
                code: this.searchKeyword,
            };

            await axios
                .post(url, data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                })
                .then((response) => {
                    this.allGithub = [];
                    if (response && response.data && response.data.data && response.data.data.items?.length) {
                        this.githubSearches = response.data.data.items;
                        this.showResult = true;
                        this.githubSearches.forEach((item, index) => {
                            this.getFragment(item.text_matches, index);
                        });
                        this.csvData.githubSearches = this.convertToCSV(this.githubSearches);
                    } else if (response) {
                        this.$toast.error("No data found");
                    }
                    this.request_id = response?.data?.query_id;
                    this.redirectTo();
                    this.resetLoader("search");
                })
                .catch((error) => {
                    this.resetLoader("search");
                    let message = "Please enter a valid input";
                    if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                    this.$toast.error(message);
                });
        },
        async getGithubUsernameData() {
            // allGithubUsernameData
            this.startLoader("github");
            this.allGithub = [];
            this.csvData = {
                githubSearches: [],
                githubUsername: [],
                github: [],
            };
            this.githubSearches = [];
            this.allGithubUsernameData = [];
            let data = {};
            let url = "/tools/github/username";
            data = {
                query: this.searchUsername,
            };
            this.$emit("searched", {
                data: {username: this.searchUsername},
                source: "github",
            });

            await axios
                .post(url, data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                })
                .then((response) => {
                    this.allGithub = [];
                    if (response && response.data && response.data?.data?.length > 0) {
                        this.allGithubUsernameData = response.data.data;
                        this.csvData.githubUsername = this.convertToCSV(this.allGithubUsernameData);
                        this.showResult = true;

                        this.SET_ALL_TOOLS_DATA({
                            ...this._data,
                            "tool-name": "github-tool",
                        });
                    } else {
                        this.$toast.error("No data found!");
                    }
                    this.request_id = response?.data?.query_id;
                    this.redirectTo();
                    this.$emit("searchResult", {
                        data: this.allGithubUsernameData,
                        source: "github",
                        searchType: "username",
                    });
                    this.resetLoader("github");
                })
                .catch((error) => {
                    this.resetLoader("github");
                    let message = "Please enter a valid input";
                    if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                    this.$toast.error(message);
                });
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const type = Object.keys(selected_query?.query)[0];

                switch (type) {
                    case "email":
                        this.searchEmail = Object.values(selected_query?.query)[0];
                        this.selectedItem = {
                            value: Object.values(selected_query?.query)[0],
                        };
                        this.inputValue = "Email";
                        this.searchType = "email";
                        this.selectVal = Object.values(selected_query?.query)[0];
                        break;
                    case "query":
                        this.searchUsername = Object.values(selected_query?.query)[0];
                        this.selectedItemUsername = {
                            value: Object.values(selected_query?.query)[0],
                        };
                        this.inputValue = "Username";
                        this.searchType = "username";
                        this.selectNameVal = Object.values(selected_query?.query)[0];
                        break;
                    case "code":
                        this.searchKeyword = Object.values(selected_query?.query)[0];
                        this.inputValue = "Code Search";
                        this.searchType = "text";
                        break;
                    default:
                        break;
                }
            } else {
                this.redirectTo();
            }
        },
        onSelect(event, type) {
            if (type == "name") {
                this.selectNameVal = event.value;
                this.$refs["analysis-tool-input"].search = event.value;
            } else {
                this.selectVal = event.value;
                this.$refs["analysis-tool-input"].search = event.value;
            }
        },

        close(type) {
            if (type == "name") {
                this.selectedItemUsername = {
                    value: this.selectNameVal,
                };
                this.searchType = "username";
                this.searchUsername = this.selectNameVal;
                this.$refs["analysis-tool-input"].search = this.selectNameVal;
            } else {
                this.selectedItem = {
                    value: this.selectVal,
                };
                this.searchType = "email";
                this.searchEmail = this.selectVal;
                this.$refs["analysis-tool-input"].search = this.selectVal;
            }
        },
        handleChange(event, type) {
            if (type == "name") {
                if (event != this.selectNameVal) {
                    this.showResult = false;
                    this.searchClicked = false;
                }
                this.selectNameVal = event;
            } else {
                if (event != this.selectVal) {
                    this.showResult = false;
                    this.searchClicked = false;
                }
                this.selectVal = event;
            }
            // this.$set(this.selectVal, type,event)
        },
        onSelectFieldType() {
            this.selectedItem = null;
            this.selectedItemUsername = null;
            this.selectVal = "";
            this.selectNameVal = "";
            if (this.$refs["analysis-tool-input"]) {
                this.$refs["analysis-tool-input"].search = "";
            }
        },
        async handleMonitoring(index, type) {
            let card_id = "";
            let card_source = "github-tool";
            let status = "";
            switch (type) {
                case "email":
                    this.allGithub[index].monitoring_status = !this.allGithub[index].monitoring_status;
                    card_id = this.allGithub[index].doc_id;
                    status = this.allGithub[index].monitoring_status ? "enabled" : "disabled";
                    break;
                case "username":
                    this.allGithubUsernameData[index].monitoring_status = !this.allGithubUsernameData[index].monitoring_status;
                    card_id = this.allGithubUsernameData[index].doc_id;
                    status = this.allGithubUsernameData[index].monitoring_status ? "enabled" : "disabled";
                default:
                    break;
            }
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id,
                card_source,
                status,
            });
        },
        async handleDone({target}, index, type) {
            let sources = ["github-tool"];
            let doc_id = "";
            switch (type) {
                case "email":
                    this.allGithub[index].changes_viewed = target.checked;
                    doc_id = this.allGithub[index].monitoring_id;
                    break;
                case "username":
                    this.allGithubUsernameData[index].changes_viewed = target.checked;
                    doc_id = this.allGithubUsernameData[index].monitoring_id;
                    break;
                default:
                    break;
            }
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id,
                visited: target.checked,
            });
        },
        async handleMarkAllAsDone({target}) {
            let sources = ["github-tool"];
            let searchType = this.searchType;
            switch (searchType) {
                case "email":
                    this.allGithub.forEach((_, index) => {
                        this.allGithub[index].changes_viewed = target.checked;
                    });
                    break;
                case "username":
                    this.allGithubUsernameData.forEach((_, index) => {
                        this.allGithubUsernameData[index].changes_viewed = target.checked;
                    });
                    break;

                default:
                    break;
            }
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources,
                mark_all_as_visited: target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },
    },
};
